* {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}
body {
    background-color: #3a4d5c;
}
.array-container {
    position: absolute;
    left: 5%;
    right: 5%;
    bottom: 0px;
    z-index: -1;
}

.array-bar {
    width: 30px;
    border-radius: 3.5px;
    display: inline-block;
    margin: 3px;
    box-shadow: 10px 0px 15px -2px rgba(0, 0, 0, 0.4);
    transition: height 0.25s;
    position: relative;
}

.top {
    z-index: 0;
    box-shadow: 8px 10px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    background-color: #1d1e2c;
    height: 128px;
}

.top #title {
    text-shadow: 2px 2px 10px #f7f7ff;
    background-color: #1d1e2c;
    font-weight: 100;
    letter-spacing: 2px;
    font-variant: small-caps;
    margin-bottom: 15px;
    color: white;
}

.button-bar {
    position: relative;
    background-color: #1d1e2c;
}
.btn {
    border-radius: 3px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.4);
    font-size: 14px;
    text-transform: uppercase;
    color: white;
    border: none;
    outline: none;
    background-color: #61a0af;
    display: inline-block;
    transition: all 0.25s;
    padding: 10px;
    margin: 15px;
    cursor: pointer;
    position: relative;
}

.btn.disabled {
    pointer-events: none;
    background-color: #92140c;
}

.btn:hover {
    color: #61a0af;
    box-shadow: 0 12px 16px 0 rgba(88, 129, 156, 0.24), 0 17px 50px 0 rgba(82, 160, 206, 0.19);
    background: white;
}

#new:hover {
    color: white;
    box-shadow: none;
    background: #61a0af;
}

.btn:active {
    color: white;
    background: #1768ac;
}

.btn:hover > .absolute {
    color: white;
}

#github-icon {
    background: none;
    justify-content: center;
    color: white;
    height: 35px;
    width: 35px;
    transition: all 0.4s;
}
#github-icon:hover {
    height: 40px;
    width: 40px;
    color: grey;
}
.homelink {
    position: absolute;
    background: transparent;
    left: 50%;
    transform: translate(-50%);
    height: 50px;
    width: 350px;
}

.speedSlider {
    position: absolute;
    background: none;
    top: 5px;
    left: 20%;
}
.barSlider {
    position: absolute;
    background: none;
    top: 5px;
    right: 20%;
}

.name-slider {
    background: none;
    color: white;
    text-shadow: 2px 2px 5px white;
}

.slider {
    height: 3px;
    width: 100%;
    transition: all 0.4s;
    border: none;
    outline: none;
}
.slider::-webkit-slider-thumb {
    cursor: pointer;
    border-radius: none;
    transition: all 0.4s;
}

.slider:disabled {
    cursor: not-allowed;
    pointer-events: none;
}

#stop-icon {
    background: none;
    color: white;
    z-index: 4;
    height: 33px;
    width: 33px;
    transition: all 0.35s;
}
#stop-icon:hover {
    color: grey;
    height: 36px;
    width: 36px;
}
#stop-link {
    background: none;
    cursor: pointer;
}
.stop-button {
    justify-content: center;
    position: absolute;
    background: none;
    border: none;
    outline: none;
    z-index: 3;
    bottom: 15%;
    left: 43px;
    height: 33px;
    width: 33px;
    transition: all 0.25s;
}

.value {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0px;
    color: blue;
    font-weight: bold;
    background: transparent;
    width: 30px;
    border-radius: 3.5px;
}

.Modal {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: papayawhip;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rebeccapurple;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
